// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "outputTop", "outputBottom","source","week_ca", "week_objectif" ]

  connect() {

  }

  update_total() {
    let total = 0
    let html = ""

    this.sourceTargets.forEach(function (source) {
      total += Number(source.value)
    })

    total = Number(total.toFixed(2))

    html = "<strong>" + total + "</strong>"
    this.outputTopTarget.innerHTML = html
    this.outputBottomTarget.innerHTML = html

  }
}
