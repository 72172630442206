// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../stylesheets/application'

import "controllers"

import 'jquery'
import 'popper.js'
import 'bootstrap'
import $ from 'jquery';
global.$ = jQuery;


function init_alert() {
    // Fermeture automatique des alerts Bootstrap
    window.setTimeout(function() {
        $(".alert").fadeTo(1000, 0).slideUp(800, function(){
            $(this).remove();
        });
    }, 2000);
}
window.init_alert = init_alert

$(document).ready(init_alert)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function search() {

    let searchString = $("#search").val();
    let url = $("#search_form").attr("action");

    if (searchString.length == 0 ) {

        $.get({
            url: url,
            success: null,
            dataType: 'script'
        });

        return true;
    }

    $.get({
        url: url,
        data: $("#search").serialize(),
        success: null,
        dataType: 'script'
    });

    return false;

}


function setObjectif() {

        let report = $('input[name=radio-reporter]:checked').val();
        let x = Number($('#input-x').val());
        let total_objectif = 0;

        $(".ligne-objectif").each(function() {

            let vente_id = $(this).val();

            let objectif_id = '#objectif-' + vente_id;
            let ca_n1 = Number( $('#ca-ttc-n1-' + vente_id ).val() );
            let objectif_n1 = Number( $('#objectif-n1-' + vente_id ).val() );
            let progression = 0;
            let objectif = 0;

            if (report == 'ca') {
                progression = (ca_n1 * x / 100);
                objectif = progression + ca_n1;
            } else if (report == 'objectif') {
                progression = (objectif_n1 * x / 100);
                objectif = progression + objectif_n1;
            }

            objectif = Number(objectif.toFixed(2));

            total_objectif += objectif

            $(objectif_id).val(objectif);

        });

        total_objectif = Number(total_objectif.toFixed(2));

        $('.total_objectif').html('<strong>' + total_objectif + '</strong>');

    }


function getVentesMagasin() {
    let code_magasin = $('#select-magasin').val();
    let url = $("#select_magasin_form").attr("action");

    let month = Number($('#date_month').val());
    let year = Number($('#date_year').val());
    let code_magasin_reference = $('#select-magasin-reference').val();
    let base_n = $('input[type=radio][name=radio-base-n]:checked').val();
    let base_date = $('input[type=radio][name=radio-base-date]:checked').val();


    $('#month').val(month);
    $('#year').val(year);


    if (code_magasin && month && year) {
        $.get({
            url: url,
            data: {
                code_magasin: code_magasin,
                year: year,
                month: month,
                code_magasin_reference: code_magasin_reference,
                base_n: base_n,
                base_date: base_date,
            },
            success: function () {
            },

            dataType: 'script'
        });
    }
}

document.addEventListener("turbolinks:load", function() {

    $('[data-toggle="tooltip"]').tooltip();

    $("#search_form input").keyup(function () {
        search();
    });

    init_alert();

    $( "#select-magasin" ).change(function() {
        getVentesMagasin();
    });

    $( "#select-magasin-reference" ).change(function() {
        getVentesMagasin();
    });

    $( '#date_month' ).change(function() {
        getVentesMagasin();
    });

    $( "#date_year" ).change(function() {
        getVentesMagasin();
    });

    $('input[type=radio][name=radio-base-n]').change(function() {
        getVentesMagasin();
    });

    $('input[type=radio][name=radio-base-date]').change(function() {
        getVentesMagasin();
    });

    $( "#input-x" ).change(function() {
        setObjectif();
    });


    $('#btn-validate-all').click(function () {

        let error = 0;
        let params = [];
        let line;

        $(".ligne-objectif").each(function() {

            let vente_id = $(this).val();

            let code_magasin = $("#select-magasin").val()
            let date         = $('#date-' + vente_id ).val();
            let objectif     = $('#objectif-' + vente_id ).val();

            line = { vente: {code_magasin: code_magasin, date: date, objectif: objectif }};
            params.push(line);

            return true;
        });

        $.post({
            url: '/ventes/save_objectifs',
            data: {ventes: params},
            success: null,
            dataType: 'script'
        });
    });

    $('input[type=radio][name=radio-reporter]').change(function() {
        setObjectif();
    });



});

